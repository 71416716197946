<template>
    <div>
        <div class="container">

            <loading-state
                    v-if="loading"
                    title="Loading today's jobs">
            </loading-state>

            <template v-if="(jobs.length > 0 || completedJobs.length > 0) && !loading">
                <!-- Page Title -->
                <div class="page-title">
                    <h4>1 of {{ jobs.length }} jobs completed <i class="fa fa-redo fa-xs ml-2" :class="{'fa-pulse': syncing}" @click="syncJobs()"></i></h4>
                </div>

                <b-tabs>
                    <!-- Outstanding Jobs -->
                    <b-tab title="To do">
                        <div class="card-table" v-if="jobs.length > 0">
                            <div class="card-link-container" v-for="(job, key) in jobs" :key="key">
                                <job-item :job="job" :jobId="job.id" :isActive="canActionJob(job)"></job-item>
                            </div>
                        </div>
                        <empty-state
                                v-if="jobs.length === 0"
                                emptyIcon
                                icon="tools"
                                title="There are no jobs for today">
                        </empty-state>
                    </b-tab>

                    <!-- Completed Jobs -->
                    <b-tab title="Done">
                        <div class="card-table" v-if="completedJobs.length > 0">
                            <div class="card-link-container" v-for="(job, key) in completedJobs" :key="key">
                                <job-item :job="job" :jobId="job.id" :isActive="canActionJob(job)"></job-item>
                            </div>
                        </div>
                        <empty-state
                                v-if="completedJobs.length === 0"
                                emptyIcon
                                icon="tools"
                                title="No jobs have been completed">
                        </empty-state>
                    </b-tab>

                    <!-- Completed Jobs -->
                    <b-tab title="All jobs">
                        <div class="card-table" v-if="allJobs.length > 0">
                            <div class="card-link-container" v-for="(job, key) in allJobs" :key="key">
                                <job-item :job="job" :jobId="job.id" :isActive="canActionJob(job)"></job-item>
                            </div>
                        </div>
                        <empty-state
                                v-if="allJobs.length === 0"
                                emptyIcon
                                icon="tools"
                                title="There are no jobs for today">
                        </empty-state>
                    </b-tab>
                </b-tabs>
            </template>

            <empty-state
                v-if="jobs.length === 0"
                emptyIcon
                icon="tools"
                title="There are no jobs for today">
            </empty-state>

        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import _ from 'lodash';

    export default {
        data() {
            return {
                loading: true,
                syncing: false
            };
        },

        mounted() {
            this.loadAllTodaysJobs()
                .then(() => {
                    this.loading = false;
                });

            this.loadTodaysCompletedJobs()
                .then(() => {
                    this.loading = false;
                });

            this.loadTodaysJobs()
                .then(() => {
                    this.loading = false;
                    this.canActionJob();
                });

        },

        computed: {
            ...mapGetters([
                "allJobs",
                "completedJobs",
                "jobs",
                "user"
            ]),

            nextActiveJobId() {
                let nextJob =  _.first(this.jobs);
                return (nextJob) ? nextJob.id : null;
            }
        },

        methods: {
            ...mapActions([
                "loadAllTodaysJobs",
                "loadTodaysCompletedJobs",
                "loadTodaysJobs"
            ]),

            canActionJob() {
                return true;
                // return (job.complete || job.id === this.nextActiveJobId);
            },

            syncJobs() {
                this.syncing = true;
                this.$axios.get(`/api/v4/technicians/${this.user.technician.id}/sync-jobs`)
                    .then(() => {
                        this.syncing = false;
                        this.loadTodaysJobs();
                        this.loadTodaysCompletedJobs();
                    })
                    .catch(() => {
                        this.syncing = false;
                    });
            }
        }
    }
</script>